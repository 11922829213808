import Swiper from "swiper"
import Blazy from "blazy"
import gsap from "gsap"
export default class Page {
    constructor() {
        this.sliders = []
        this.swiper()
        this.blazy()
    }

    swiper() {  
        this.sliders = []
        document.querySelectorAll(".swiper").forEach((item) => {
            const slide = new Swiper(item, {
                loop: true,
                pagination: {
                    el: '.swiper-pagination'
                },
                on: {
                    slideChange: (item) => {
                        this.loadImagesInSlide(item)
                    }
                }
            })
            this.sliders.push(slide)
        })
    }

    blazy() {
        return new Blazy({
            success: (el) => {
                el.parentElement.classList.add("loaded")
            }
        })
    }

    loadImagesInSlide(swiperInstance) {
        const activeSlide = swiperInstance.slides[swiperInstance.activeIndex]
        const images = activeSlide.querySelectorAll('img[data-src]')
        images.forEach((img) => this.loadImage(img))
    }

    loadImage(_img) {
        const img = new Image()
        img.onload = () => {
            _img.src = _img.dataset.src
            _img.parentElement.classList.add("loaded")
        }
        img.src = _img.dataset.src
    }
}