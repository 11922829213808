import Layout from "../layout"
import Page from "../components/page"
import Newsletter from "../components/newsletter"
import Menu from "../components/menu"
import Cart from "../components/cart"
import Package from "../components/package"
// import Product from "../components/product.js"
import PhotoEditor from "../components/photo-editor.js"
import Alert from "../admin/alert"

class App extends Layout {
    constructor() {
        super()
        window["CART"] = new Cart()
        this.menu = new Menu()
        this.editor = new PhotoEditor()
        this.package = new Package()
        this.newsletter = new Newsletter()
        this.page = new Page()
        document.querySelectorAll('.alert').forEach(item => new Alert(item))
        document.querySelectorAll('.change-page').forEach(link => this.onChangeClick(link))
    }

    onChangeClick(_link) {
        _link.addEventListener("click", (e) => {
            const href = _link.getAttribute("href")
            e.preventDefault()
            if (window.location.pathname != href) {
                this.preloader.show(href)
            }
        })
    }
}

const _app = new App()