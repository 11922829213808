import { openEditor, createDefaultImageReader, createDefaultImageWriter, processImage, getEditorDefaults } from '@pqina/pintura';
import locale_es from '@pqina/pintura/locale/es_ES';
import FilePondPluginImageEditor from "@pqina/filepond-plugin-image-editor";
import gsap from 'gsap';
// Register FilePond plugins
FilePond.registerPlugin(
    FilePondPluginImageEditor,
    FilePondPluginFilePoster,
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType,
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginImageEdit
);

locale_es.labelButtonExport = "Finalizar"

FilePond.setOptions({
    labelInvalidField: "El campo contiene archivos inválidos",
    labelFileWaitingForSize: "Procesando dimensiones",
    labelFileSizeNotAvailable: "Tamaño no disponible",
    labelFileCountSingular: "archivo in lista",
    labelFileCountPlural: "archivos en lista",
    labelFileLoading: "Cargando",
    labelFileAdded: "Añadida",
    labelFileLoadError: "Error al cargar",
    labelFileRemoved: "Eliminado",
    labelFileRemoveError: "Error durante la eliminación",
    labelFileProcessingRevertError: "Error durante el revert",
    labelIdle: 'Suelta aquí tus imágenes o <span class="filepond--label-action">Selecciona desde tu dispositivo</span>',
    labelFileProcessing: 'Subiendo...',
    labelFileProcessingComplete: 'Subida completa',
    labelFileProcessingAborted: 'Subida cancelada',
    labelFileProcessingError: 'Error durante la subida',
    labelTapToCancel: 'Toca para cancelar',
    labelTapToRetry: 'Toca para reintentar',
    labelTapToUndo: 'Toca para deshacer',
    labelButtonRemoveItem: 'Eliminar',
    labelButtonAbortItemLoad: 'Abortar',
    labelButtonRetryItemLoad: 'Reintentar',
    labelButtonAbortItemProcessing: 'Cancelar',
    labelButtonUndoItemProcessing: 'Deshacer',
    labelButtonRetryItemProcessing: 'Reintentar',
    labelButtonProcessItem: 'Subir',
    labelMaxFileSizeExceeded: 'El archivo es demasiado grande',
    labelMaxFileSize: 'El tamaño máximo del archivo es {filesize}',
    labelMaxTotalFileSizeExceeded: 'El tamaño total de los archivos excede el límite',
    labelMaxTotalFileSize: 'El tamaño total máximo de los archivos es {filesize}',
    labelFileTypeNotAllowed: 'Tipo de archivo no permitido',
    fileValidateTypeLabelExpectedTypes: 'Espera {allButLastType} o {lastType}',
    imageValidateSizeLabelFormatError: 'El tipo de imagen no es compatible',
    imageValidateSizeLabelImageSizeTooSmall: 'La imagen es demasiado pequeña',
    imageValidateSizeLabelImageSizeTooBig: 'La imagen es demasiado grande',
    imageValidateSizeLabelExpectedMinSize: 'El tamaño mínimo es {minWidth} x {minHeight}',
    imageValidateSizeLabelExpectedMaxSize: 'El tamaño máximo es {maxWidth} x {maxHeight}',
    imageValidateSizeLabelImageResolutionTooLow: 'La resolución es demasiado baja',
    imageValidateSizeLabelImageResolutionTooHigh: 'La resolución es demasiado alta',
});

const CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export default class PhotoEditor {
    constructor() {
        const DPI = 300;
        this.width = WIDTH;
        this.height = HEIGHT;
        // convert cm to px
        this.width = Math.round(WIDTH * (DPI / 2.54));
        this.height = Math.round(HEIGHT * (DPI / 2.54));

        this.aspect_ratio = ASPECT_RATIO;
        let pond = document.querySelector('.input-files')
        if (!pond) return ".input-files es requerido en el DOM"
        this.initFilePond(pond);
    }

    initFilePond(container) {
        const pond = FilePond.create(container, {
            allowMultiple: true,
            allowReorder: false,
            allowFilePoster: true,
            filePosterMaxHeight: 256,
            imagePreviewHeight: 256,
            imageResizeTargetWidth: 200,
            imageResizeTargetHeight: 200,
            instantUpload: false,
            credits: false,
            forceRevert: true,
            imageEditInstantEdit: false,
            allowImagePreview: true,
            allowImageExifOrientation: false,
            server: this.getFilePondServerConfig(),
            imageEditor: this.getImageEditor()
        });
        // Manejar el evento cuando se agrega un archivo
        pond.on('warning', (error, file) => {
            // Verificar si se excede el límite de fotos permitidas
            const counter = file.length + pond.getFiles().length
            if (counter > PHOTOS_QUANTITY) {
                this.showAlert(`Sólo puedes seleccionar ${PHOTOS_QUANTITY} imágenes.`);
                return;
            }
        })
        pond.on('addfile', (error, file) => {
            this.checkImageResolution(file.file);
            this.updatePhotosCounter(pond);
        });

        pond.on('removefile', () => this.updatePhotosCounter(pond));
    }

    checkImageResolution(file) {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                const minWidth = this.width; // Resolución mínima en píxeles
                const minHeight = this.height; // Resolución mínima en píxeles

                if (img.width < minWidth || img.height < minHeight) {
                    this.showLowResolutionWarning(img.width, img.height, minWidth, minHeight);
                }
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    }

    showLowResolutionWarning(actualWidth, actualHeight, minWidth, minHeight) {
        const warningElement = document.querySelector('#low-resolution-warning');
        if (warningElement) {
            warningElement.innerText = `Advertencia: La resolución de la imagen (${actualWidth}x${actualHeight}px) es menor que la recomendada (${minWidth}x${minHeight}px) para impresión.`;
            warningElement.style.display = 'block';
        }
    }

    getImageEditor() {
        return {
            // used to create the editor, receives editor configuration, should return an editor instance
            createEditor: openEditor,
            // Required, used for reading the image data
            imageReader: [createDefaultImageReader],
            // optionally. can leave out when not generating a preview thumbnail and/or output image
            imageWriter: [
                createDefaultImageWriter,
                // optional image writer instructions, this instructs
                // the image writer to resize the image to match a width of 384 pixels
                {
                    targetSize: {
                        width: this.width,
                        height: this.height,
                    },
                    mimeType: 'image/jpeg', // Asegurar que el archivo sea JPEG
                    quality: 0.9, // Calidad de la imagen (90%)
                },
            ],
            // used to generate poster images, runs an editor in the background
            imageProcessor: processImage,
            // Pintura Image Editor properties
            editorOptions: {
                // pass the editor default configuration options
                ...getEditorDefaults(),
                imageCropAspectRatio: ASPECT_RATIO, // Mantener el mismo radio de aspecto
                enableCropAspectRatioSelection: false, // Deshabilitar la selección de proporción de recorte
                locale: locale_es
            },
        }
    }

    getFilePondServerConfig() {
        return {
            process: {
                url: '/photos/upload', // Endpoint para subir imágenes
                method: 'POST',
                headers: {
                    'X-CSRF-Token': CSRF_TOKEN, // Incluir el token CSRF
                },
            },
            revert: {
                url: '/photos/revert', // Endpoint para revertir cambios
                method: 'DELETE',
                headers: {
                    'X-CSRF-Token': CSRF_TOKEN, // Incluir el token CSRF
                },
            },
            load: '/photos/load', // Endpoint para cargar imágenes
            fetch: null, // No se necesita fetch en este caso
        }
    }

    showAlert(_msg) {
        let msg = document.querySelector("#editor-alert-message")
        msg.innerText = _msg
        gsap.set("#editor-alert", {
            display: "hidden",
            opacity: 0,
            y: 15
        })
        gsap.to("#editor-alert", {
            display: "flex",
            ease: "back.out(.7)",
            duration: .45,
            opacity: 1,
            y: 0,
            onComplete: () => {
                gsap.to("#editor-alert", {
                    display: "hidden",
                    ease: "back.in(.7)",
                    duration: .45,
                    delay: 1,
                    opacity: 0,
                    y: -15
                })
            }
        })
    }
    
    updatePhotosCounter(pond) {
        const photosCounter = document.querySelector('#photos-selected-counter');
        if (photosCounter) {
            photosCounter.innerText = pond.getFiles().length; // Actualizar el texto del contador
        }
    }
}