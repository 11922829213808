export default class Package {
    constructor() {
        this.container = document.querySelector("#product-packages");
        if (!this.container) return false;

        // Inicializar las propiedades seleccionadas
        this.selectedPaper = this.getActiveValue('paper');
        this.selectedQuantity = this.getActiveValue('quantity');
        this.selectedFormat = this.getActiveValue('format');
        this.selected_package = null;

        // Elementos del DOM donde se mostrará la información del paquete
        this.cost_element = document.querySelector('#package-cost');
        this.paper_element = document.querySelector("#package-paper");
        this.quantity_element = document.querySelector("#package-quantity");
        this.img_element = document.querySelector("#package-img");
        this.format_element = document.querySelector("#package-format");
        this.selectPhotosBtn = document.querySelector("#select-photos-btn");

        // Inicializar eventos y configuración
        this.init();
    }

    // Obtener el valor activo de un atributo
    getActiveValue(attr) {
        const activeButton = document.querySelector(`.package-prop-btn[data-attr='${attr}'].active`);
        return activeButton ? activeButton.getAttribute('value') : null;
    }

    // Inicializar eventos y configuración
    init() {
        document.querySelectorAll('.package-prop-btn').forEach(button => {
            button.addEventListener('click', (event) => this.handleButtonClick(event));
        });
        this.filterQuantities();
        this.setPackage();
    }

    // Manejar el evento de clic en los botones de propiedades del paquete
    handleButtonClick(event) {
        const button = event.target;
        const attr = button.getAttribute('data-attr');
        const value = button.getAttribute('value');

        this.updateSelection(attr, value, button);
        this.filterQuantities();
        this.setPackage();
    }

    // Actualizar la selección de propiedades del paquete
    updateSelection(attr, value, button) {
        if (attr === 'paper') {
            this.selectedPaper = value;
        } else if (attr === 'quantity') {
            this.selectedQuantity = value;
        } else if (attr === 'format') {
            this.selectedFormat = value;
        }

        this.updateActiveButton(attr, button);
    }

    // Actualizar los textos de los elementos del DOM con la información seleccionada
    updateTexts() {
        if (this.selected_package) {
            this.paper_element.innerText = this.selected_package.paper;
            this.quantity_element.innerText = this.selected_package.quantity;
            this.format_element.innerText = this.selected_package.format;
            this.cost_element.textContent = `$${this.selected_package.cost}`;
            this.img_element.src = this.selected_package.img;
        }
    }

    // Resetear los textos de los elementos del DOM cuando no hay paquete seleccionado
    resetPackageInfo() {
        this.paper_element.innerText = '';
        this.quantity_element.innerText = '0';
        this.format_element.innerText = '';
        this.cost_element.textContent = '$';
        this.img_element.src = '/not-found.png';
        this.selectPhotosBtn.setAttribute('href', '#');
        this.selectPhotosBtn.classList.add('hidden')
    }

    // Actualizar el botón activo
    updateActiveButton(attr, button) {
        const active = document.querySelector(`.package-prop-btn[data-attr='${attr}'].active`);
        if (active) active.classList.remove('active');
        button.classList.add('active');
    }

    // Filtrar las cantidades disponibles según las propiedades seleccionadas
    filterQuantities() {
        const quantityButtons = document.querySelectorAll('.package-prop-btn[data-attr="quantity"]');
        quantityButtons.forEach(button => {
            const quantity = button.getAttribute('value');
            let showButton = true;

            if (this.selectedPaper) {
                showButton = showButton && this.checkPackageExists(this.selectedPaper, quantity, this.selectedFormat);
            }

            if (this.selectedFormat) {
                showButton = showButton && this.checkPackageExists(this.selectedPaper, quantity, this.selectedFormat);
            }

            if (showButton) {
                button.classList.remove('hidden');
            } else {
                button.classList.add('hidden');
            }
        });
    }

    // Verificar si existe un paquete con las propiedades seleccionadas
    checkPackageExists(paper, quantity, format) {
        return PACKAGES.some(pkg => 
            (!paper || pkg.paper === paper) &&
            (!quantity || pkg.quantity == quantity) &&
            (!format || pkg.format === format)
        );
    }

    // Establecer la información del paquete seleccionado en los elementos del DOM
    setPackage() {
        if (this.selectedPaper && this.selectedQuantity && this.selectedFormat) {
            this.selected_package = PACKAGES.find(pkg => 
                pkg.paper === this.selectedPaper &&
                pkg.quantity == this.selectedQuantity &&
                pkg.format === this.selectedFormat
            );
            if (this.selected_package) {
                this.updateSelectPhotosLink(this.selected_package.id);
                this.updateTexts();
            } else {
                this.resetPackageInfo();
            }
        } else {
            this.resetPackageInfo();
        }
    }

    // Actualizar el enlace del botón "Seleccionar Fotos" con el ID del paquete seleccionado
    updateSelectPhotosLink(packageId) {
        const category = this.selectPhotosBtn.dataset.category
        const slug = this.selectPhotosBtn.dataset.slug
        this.selectPhotosBtn.setAttribute('href', `/productos/${category}/${slug}/${packageId}/seleccion`);
        this.selectPhotosBtn.classList.remove('hidden')
    }
}